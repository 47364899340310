import React, { useEffect, useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import AxiosInstance from 'src/components/Axios'
import { useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import MyToastify from 'src/components/myComponents/MyToactify'
import css from './MyJobs.module.scss'
import CreateJobForm from 'src/components/jobs/CreateJobForm'
import ExistJobForm from 'src/components/jobs/jobsUpdate/ExistJobForm'

const urlApi = '/jobs'
const urlApi_2 = '/job_workers'
const urlApiWorkers = '/workers'
const urlRouter = '/jobs'
const urlMedia = '/media_objects'
const urlApiEngineers = '/engineers'
const urlApiUsers = '/users'

// токен
const tokenStr = localStorage.getItem('user')
const header = { headers: { Authorization: `Bearer ${tokenStr}`, accept: 'application/json' } }

// id користувача
const us = localStorage.getItem('user_data')
const role = localStorage.getItem('user_role')

export async function LoaderMyJobsUpdate({ params }) {
  const id = params.pid

  if (Object.keys(params).length !== 0) {
    try {
      const response1 = await AxiosInstance.get(`${urlApi}/${id}`, header)
      // const response2 = await AxiosInstance.get(`${urlApi}/${response1.data.id}`, header)

      // змінити дата 2
      // return { data: response1.data, data2: response1.data }
      return { data: response1.data }
    } catch (error) {
      console.error('Помилка під час виконання запитів', error)
      return null
    }
  }
  return null
}

const MyJobsForm = () => {
  const navigate = useNavigate()

  // дані для оновлення
  const { data } = useLoaderData() || {}
  // console.log('data', data)

  const [dataState, setDataState] = useState(data != undefined ? data : null)
  // console.log('dataState', dataState);
  const initialDate = new Date().toISOString().split('T')[0]
  const [userId, setUserId] = useState(false)
  const [name, setName] = useState(data !== undefined ? data.fullName : '')
  const [description, setDescription] = useState(data !== undefined ? data.description : '')
  const [descriptionDateStart, setDescriptionDateStart] = useState(initialDate)
  const [descriptionDateEnd, setDescriptionDateEnd] = useState(initialDate)
  const [selectedOptionPartner, setSelectedOptionPartner] = useState(null)
  const [selectedOptionFactories, setSelectedOptionFactories] = useState(null)
  const [selectedOptionWorkers, setSelectedOptionWorkers] = useState(null)
  const [selectedOptionEngineers, setSelectedOptionEngineers] = useState(null)
  const [selectedEngineer, setSelectedEngineer] = useState(null)
  const [isMainEngineerOp, setIsMainEngineerOp] = useState(null);

  // useEffect(() => {
  //   // console.log('перевірка', selectedOptionPartner)
  //   console.log('що в selectedOptionWorkers', selectedOptionWorkers)
  // }),
  //   [selectedOptionWorkers]

  // Викликає popup з власного компонента
  const popup = MyToastify()

  // Текст для popup
  const popupTextPostSuccess = 'Заявку створено'
  const popupTextPutSuccess = 'Дані оновлено'

  // поточна дата
  const currentDate = new Date()

  // Заповнити Id користувача
  useEffect(() => {
    if (us != null) {
      let userData = JSON.parse(us)
      setUserId(userData.id)
    }
  }, [us])

  // Зробити запит за даними користувача
  useEffect(() => {
    // console.log('userId', userId);
    if (userId) {
      getUserData(userId);
      // getUserData(379);
    };
  }, [userId]);

  // Функція для запиту за даними
  const getData = () => {
    AxiosInstance.get(`${urlApi}/${dataState.id}`, header).then((response) => {
      // console.log('getData response ', response);
      if (response.status === 200) {
        setDataState(response.data);
      } else {
        console.log("Помилка get-запиту");
      }
    })
  }

  // Функція для запиту за даними користувача
  const getUserData = async (id) => {
    try {
      const response = await AxiosInstance.get(`${urlApiUsers}/${id}`);
      // console.log('get users/id/ response.data', response.data);
      if (response.data?.isMainEngineerOp) {
        setIsMainEngineerOp(response.data?.isMainEngineerOp);
      } else {
        setIsMainEngineerOp(false);
      };
    } catch (error) {
      setIsMainEngineerOp(false);
      console.error("Помилка запиту за даними користувача", error);
    };
  };

  // Наповнювати "Підрозділ" та "Відповідальний інженер"
  useEffect(() => {
    if (dataState != undefined) {
      setSelectedOptionFactories(dataState?.factory ? { label: dataState.factory.name, value: dataState.factory.id } : null);
      setSelectedEngineer(dataState?.engineer ? { label: dataState.engineer.name, value: dataState.engineer.id } : null);
    }
  }, [dataState]);

  // Викликати ф-цію запиту за списком для "Відповідальний інженер"
  useEffect(() => {
    // console.log('selectedOptionFactories', selectedOptionFactories);
    if (selectedOptionFactories != null) {
      loadOptionsEngineers(selectedOptionFactories);
    }
  }, [selectedOptionFactories]);

  // Функція для відправки даних
  const handleSubmit = (event) => {
    event.preventDefault()

    // якщо робота є
    if (dataState) {

      // console.log("jobs put");
      // дані для створення роботи
      const data_response = {
        factory: { label: selectedOptionFactories.label, id: selectedOptionFactories.value },
        engineer: `/api/engineers/${selectedEngineer.value}`,
      }

      // console.log('data_response', data_response);

      const response = AxiosInstance.put(`${urlApi}/${dataState.id}`, data_response, header).then(
        (response) => {
          if (response.status === 200) {
            // Popup про успішні зміни
            popup.toastifySuccess(popupTextPutSuccess)
            // return navigate(urlRouter)
            // Оновити дані
            getData();
          } else {
            console.log("Помилка put-запиту")
          }
        },
      )
      // якщо робота відсутня
    } else {
      const data_response = {
        name: name,
        account: `/api/accounts/${selectedOptionPartner.value}`,
        description: description,

        dateEntered: currentDate.toISOString(),
        date: descriptionDateStart,
        dateStart: descriptionDateStart,
        dateEnd: descriptionDateEnd,
        factory: { label: selectedOptionFactories.label, id: selectedOptionFactories.value },
        engineer: `/api/engineers/${selectedEngineer.value}`,
        status: 'new'
      }

      // console.log("jobs post");
      // console.log('data_response', data_response);

      const response = AxiosInstance.post(`${urlApi}`, data_response, header).then((response) => {
        // console.log('res', response);
        if (response.status === 201) {
          // це обєкт для звязку
          const data_response_2 = {
            job: `/api/jobs/${response.data.id}`,
            description: response.data.description,
            dateJob: response.data.date,
            worker: `/api/workers/${selectedOptionWorkers.id}`,
          }

          AxiosInstance.post(`${urlApi_2}`, data_response_2, header).then((response) => {
            // console.log('response звязок', response)
          })

          // Popup про успішні зміни
          popup.toastifySuccess(popupTextPostSuccess)
          return navigate(urlRouter)
        }
      })
    }
  }

  // завантаження опцій підрядників у форму
  const loadOptionsPartners = async (inputValue) => {
    if (role === 'ROLE_ADMIN') {
      // console.log('контрагенти адміна')
      return await AxiosInstance.get(`/accounts`, header).then((response) => {
        // console.log(31231323,response);
        return response.data.map((result) => ({
          label: result.name,
          value: result.id,
        }))
      })
    } else {
      if (userId !== false) {
        return await AxiosInstance.get(`/users/${userId}`, header).then((response) => {
          // console.log(31231323, response)
          return response.data.accounts.map((result) => ({
            description: description,
            label: result.name,
            value: result.id,
          }))
        })
      }
    }
  }


  // завантаження опцій підрядників у форму
  const loadOptionsFactories = async (inputValue) => {

    return await AxiosInstance.get(`/factories`, header).then((response) => {
      // console.log(31231323,response);
      const arrFactories = response.data.map((result) => ({
        label: result.name,
        value: result.id,
      }))
      // console.log('arrFactories', arrFactories);
      return arrFactories
    })
  }

  // при зміні опції підрядника
  const handleChangePartner = (select) => {
    setSelectedOptionPartner(select)
    loadOptionsWorkers(select)
  }

  // Функція для зміни підрядника, запиту за спискаим інженерів та очищення вибраного інженера
  const handleChangeFactories = (select) => {
    setSelectedOptionFactories(select)
    loadOptionsEngineers(select)

    // При зміні підрядника - очитити інженера
    setSelectedEngineer(null);
  }

  const handleChangeEngineers = (select) => {
    setSelectedEngineer(select)
    // loadOptionsEngineers(select)
  }

  // Функція для відміни змін значень для "Підрядник" та "Відповідальний інженер"
  const cancelChange = () => {
    setSelectedOptionFactories(dataState?.factory ? { label: dataState.factory.name, value: dataState.factory.id } : null);
    setSelectedEngineer(dataState?.engineer ? { label: dataState.engineer.name, value: dataState.engineer.id } : null);
  };

  //  завантаження опцій інженерів у форму залежно від заводу
  const loadOptionsEngineers = async (selectedFactory) => {
    // console.log('завантаження інженерів');
    // // let request = `${urlApiEngineers}?`
    // // if (selectedFactory) request += `&urlApiEngineers.account.id=${selectedFactory.id}`
    // https://api-cp-diamant.inneti.net/api/factories?name=asdasda

    return await AxiosInstance.get(`/factories?name=${selectedFactory.label}`, header).then((response) => {
      // console.log('res1', response);
      const engineersOptions = response.data[0].engineers.map((result) => ({
        label: result.name,
        value: result.id,
      }))
      // console.log('res2', engineersOptions);
      // console.log('selectedOptionEngineers before:', selectedOptionEngineers);
      setSelectedOptionEngineers(engineersOptions);
      // console.log('selectedOptionEngineers after:', selectedOptionEngineers);
      return engineersOptions
    })


    // console.log('завантаження інженерів');
    // // let request = `${urlApiEngineers}?`
    // // if (selectedFactory) request += `&urlApiEngineers.account.id=${selectedFactory.id}`

    // return await AxiosInstance.get(`${urlApiEngineers}`, header).then((response) => {
    //   const engineersOptions = response.data.map((result) => ({
    //     label: result.name,
    //     id: result.id,
    //   }))
    //   console.log('res1',engineersOptions);

    //   console.log('selectedOptionEngineers before:', selectedOptionEngineers);
    //   setSelectedOptionEngineers(engineersOptions);
    //   console.log('selectedOptionEngineers after:', selectedOptionEngineers);

    //   return engineersOptions
    // })
  }


  //  завантаження опцій працівників у форму залежно від підрядника
  const loadOptionsWorkers = async (selectedPartner) => {
    let request = `${urlApi}?`
    if (selectedPartner) request += `&accountWorkers.account.id=${selectedPartner.id}`

    return await AxiosInstance.get(`${urlApiWorkers}?${request}`, header).then((response) => {
      const workerOptions = response.data.map((result) => ({
        label: result.fullName,
        id: result.id,
      }))

      setSelectedOptionWorkers(workerOptions)
      return workerOptions
    })
  }

  //  при зміні опції працівника
  const handleChangeWorkers = (select) => {
    setSelectedOptionWorkers(select)
  }


  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      {/* форма створити роботу */}
      {
        // (!data && userId !== false) &&
        (!dataState && userId !== false) &&
        <CreateJobForm
          data={dataState}
          handleSubmit={handleSubmit}
          loadOptionsPartners={loadOptionsPartners}
          selectedOptionPartner={selectedOptionPartner}
          handleChangePartner={handleChangePartner}
          name={name}
          setName={setName}
          description={description}
          setDescription={setDescription}
          descriptionDateStart={descriptionDateStart}
          setDescriptionDateStart={setDescriptionDateStart}
          descriptionDateEnd={descriptionDateEnd}
          setDescriptionDateEnd={setDescriptionDateEnd}
          loadOptionsFactories={loadOptionsFactories}
          selectedOptionFactories={selectedOptionFactories}
          handleChangeFactories={handleChangeFactories}
          selectedOptionEngineers={selectedOptionEngineers}
          selectedEngineer={selectedEngineer}
          handleChangeEngineers={handleChangeEngineers}
          urlRouter={urlRouter}
        />
      }


      {/* форма існуючої роботи */}
      {
        // (data && userId != false) &&
        (dataState && userId != false) &&
        <ExistJobForm
          data={dataState} assignedUserId={userId}
          handleSubmit={handleSubmit}
          css={css}
          role={role}
          popup={popup}
          loadOptionsFactories={loadOptionsFactories}
          selectedOptionFactories={selectedOptionFactories}
          handleChangeFactories={handleChangeFactories}
          selectedOptionEngineers={selectedOptionEngineers}
          selectedEngineer={selectedEngineer}
          handleChangeEngineers={handleChangeEngineers}
          cancelChange={cancelChange}
          isMainEngineerOp={isMainEngineerOp}
          currentDate={currentDate}
        />
      }
    </>
  )
}

export default MyJobsForm

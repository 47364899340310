import React, { useEffect, useState } from 'react';
import { CButton, CFormCheck } from '@coreui/react';

// Блок кнопок
const ButtonsBlock = (props) => {
    // console.log('ButtonsBlock props', props);

    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        if (props?.dataState?.statusMainEngineerOp === "checked") {
            setIsChecked(true);
        } else {
            setIsChecked(false);
        };
    }, [props?.dataState?.statusMainEngineerOp]);

    return (
        <div className="d-flex align-items-center">
            {
                (!props.isDisabledChange && !props.isSendForInspection) &&
                <CButton onClick={() => props.changeStatus({ status: "not_checked" })} color="success" disabled={props.isDisabledSend}>
                    Відправити на перевірку
                </CButton>
            }

            {/* Редагувати файли (status="not_checked") */}
            {
                (props.isDisabledChange && props.isSendForInspection) &&
                <CButton onClick={() => props.changeStatus({ status: "new" })} color="success">
                    Редагувати
                </CButton>
            }

            {/* Відображати тільки для Адміна */}
            {
                (props.role === "ROLE_ADMIN") &&
                <div className="d-flex align-items-center">
                    {/* Затвердити файли (status="checked") */}
                    {
                        (props.dataState?.status === "not_checked") &&
                        <CButton
                            className="ms-3"
                            // onClick={() => props.changeStatus({ status: "checked"})}
                            onClick={() => props.setOpenWarningForEngineer(true)}
                            color="success"
                            disabled={props.dataState?.statusMainEngineerOp !== "checked"}
                        >
                            Перевірено
                        </CButton>
                    }


                    {/* Повернути файли на доопрацювання (status="rejected") */}
                    {
                        (props.dataState?.status === "not_checked") &&
                        <>
                            <CButton
                                className="ms-3"
                                onClick={() => props.addComentar(props.comentar)}
                                color="success"
                                disabled={props.dataState?.status === "rejected" || props.comentar === ""}
                            >
                                {(props.dataState?.comentar && props.dataState.comentar !== "") ? "Оновити зауваження" : "Додати зауваження"}
                            </CButton>
                        </>
                    }

                    {/* Показувати тільки Старшому інженер з ОП якщо ще не було погоджено */}
                    {
                        (props?.isMainEngineerOp && props?.dataState?.statusMainEngineerOp !== "checked" && props.dataState?.status === "not_checked") &&
                        <CButton className="ms-3" color="success" onClick={() => props.setOpenWarningForMainEngineerOp(true)}>
                            Погоджую, Старший інженер з ОП
                        </CButton>
                    }

                    {/* Показувати тільки Старшому інженер з ОП якщо вже було погоджено */}
                    {
                        (((props?.isMainEngineerOp && props?.dataState?.statusMainEngineerOp === "checked") || (!props?.isMainEngineerOp && props?.isMainEngineerOp !== null)) && props.dataState?.status === "not_checked") &&
                        <CFormCheck
                            className="ms-3"
                            id="flexCheckDefault"
                            label="Погоджено, Старший інженер з ОП"
                            checked={isChecked}
                            disabled={true}
                        />
                    }
                </div>
            }
        </div>
    );
};

export default ButtonsBlock;
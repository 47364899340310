import React, { useEffect, useState } from 'react';
import { Modal, StyledEngineProvider, Typography, Box } from '@mui/material';
import { CButton } from '@coreui/react';

// Попередження при зміні status="checked"
const WarningWhenChecked = (props) => {

    // Стилі для модального вікна
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 1,
    };

    const textForEngineer = <>
        <h2>Попередження!!!</h2>
        <p>Після підтвердження роботи "{props.jobName}" зміни можна буде вносити тільки в 1С</p>
    </>
    const textForMainEngineerOp = <>
        <h2>Увага!</h2>
        <p>Ви впевнені, що хочете підтвердити виконання роботи "{props.jobName}"?</p>
    </>
    const [textForMessage, setTextForMessage] = useState("");
    const [textForBtn, setTextForBtn] = useState("");
    const [isDisabledBtn, setIsDisabledBtn] = useState(true);

    // Функція для підтвердження та закриття модального вікна
    const handleChange = () => {
        // Підтвердити - для інженера
        if (props?.userRoleForWarningModal === "Engineer") {
            props.changeStatus({ status: "checked" });
        };

        // Погодити - для Старшого інженера з ОП
        if (props?.userRoleForWarningModal === "MainEngineerOp") {
            props.changeStatus({ statusMainEngineerOp: "checked" });
        };
        // Закрити модальне вікно
        props.handleCloseWarning();
    };

    // Заповнювати дані відповідно до випадку, для якого відкрите модальне вікно
    useEffect(() => {
        if (props?.userRoleForWarningModal === "Engineer") {
            setTextForMessage(textForEngineer);
            setTextForBtn("Перевірено");
        } else if (props?.userRoleForWarningModal === "MainEngineerOp") {
            setTextForMessage(textForMainEngineerOp);
            setTextForBtn("Погоджую");
        } else {
            setTextForMessage("");
            setTextForBtn("");
        };

        if (props?.userRoleForWarningModal) {
            setIsDisabledBtn(false);
        } else {
            setIsDisabledBtn(true);
        };
    }, [props?.userRoleForWarningModal]);

    return (
        <StyledEngineProvider injectFirst>
            <Modal
                open={props.openWarning}
                onClose={props.handleCloseWarning}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <div style={{ padding: "10px", textAlign: "center", color: "red" }}>
                            {textForMessage}
                            <CButton
                                className="me-3"
                                onClick={() => handleChange()}
                                color="success"
                                disabled={isDisabledBtn}
                            >
                                {textForBtn}
                            </CButton>
                            <CButton color="secondary" onClick={() => props.handleCloseWarning()}>Відміна</CButton>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </StyledEngineProvider>
    );
};

export default WarningWhenChecked;
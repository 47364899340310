import React, { useEffect, useState } from 'react';
import { CForm, CFormLabel, CFormInput, CButton, CListGroup, CListGroupItem, CFormCheck } from '@coreui/react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { useConvertToLocalTime } from 'src/components/store/useConvertToLocalTime';


// Компонент з загальними даними про роботу
const JobData = (props) => {
    // console.log('JobData props', props);

    const [isDisabledCancelBtn, setIsDisabledCancelBtn] = useState(true);
    const [isDisabledPutBtn, setIsDisabledPutBtn] = useState(true);

    // Відлідковувати дані для блокування/розблокування кнопок
    useEffect(() => {
        // Заблокувати кнопку "Відмінити зміни"
        if ((props.selectedOptionFactories?.label === props.data.factory.name) && (props.selectedEngineer?.label === props.data.engineer.name)) {
            setIsDisabledCancelBtn(true);
        } else {
            setIsDisabledCancelBtn(false);
        }

        // Заблокувати кнопку "Зберегти"
        if ((props.selectedOptionFactories === null || props.selectedEngineer === null) ||
            ((props.selectedOptionFactories?.label === props.data.factory.name) && (props.selectedEngineer?.label === props.data.engineer.name))) {
            setIsDisabledPutBtn(true);
        } else {
            setIsDisabledPutBtn(false);
        }
    }, [props.data, props.selectedOptionFactories, props.selectedEngineer]);

    return (
        <>
            <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlInput1">Контрагент</CFormLabel>
                <CFormInput
                    type="text"
                    id="exampleFormControlInput1"
                    value={props.data.account.name}
                    disabled={true}
                    autoComplete="off"
                />
            </div>

            <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlInput1">Назва роботи</CFormLabel>
                <CFormInput
                    type="text"
                    id="exampleFormControlInput1"
                    value={props.data.name}
                    autoComplete="off"
                    disabled={true}
                />
            </div>

            <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlInput2">Опис</CFormLabel>
                <CFormInput
                    type="text"
                    id="exampleFormControlInput2"
                    value={props.data.description}
                    autoComplete="off"
                    disabled={true}
                />
            </div>

            <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlInput2">Дата початку</CFormLabel>
                <CFormInput
                    type="text"
                    id="exampleFormControlInput2"
                    value={props.data?.dateStart ? useConvertToLocalTime(props.data.dateStart) : "❌"}
                    autoComplete="off"
                    disabled={true}
                />
            </div>

            <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlInput2">Дата закінчення</CFormLabel>
                <CFormInput
                    type="text"
                    id="exampleFormControlInput2"
                    value={props.data?.dateEnd ? useConvertToLocalTime(props.data.dateEnd) : "❌"}
                    autoComplete="off"
                    disabled={true}
                />
            </div>

            <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlInput2">Дата створення</CFormLabel>
                <CFormInput
                    type="text"
                    id="exampleFormControlInput2"
                    value={props.data?.dateEntered ? useConvertToLocalTime(props.data.dateEntered) : "❌"}
                    autoComplete="off"
                    disabled={true}
                />
            </div>

            <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlInput2">Дата початку аудиту</CFormLabel>
                <CFormInput
                    type="text"
                    id="exampleFormControlInput2"
                    value={props.data?.dateStartAudit ? useConvertToLocalTime(props.data.dateStartAudit) : "❌"}
                    autoComplete="off"
                    disabled={true}
                />
            </div>

            <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlInput2">Дата аудиту</CFormLabel>
                <CFormInput
                    type="text"
                    id="exampleFormControlInput2"
                    value={props.data?.dateAudit ? useConvertToLocalTime(props.data.dateAudit) : "❌"}
                    autoComplete="off"
                    disabled={true}
                />
            </div>

            <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlInput2">Дата відправки в 1С</CFormLabel>
                <CFormInput
                    type="text"
                    id="exampleFormControlInput2"
                    value={props.data?.dateSendC1 ? useConvertToLocalTime(props.data.dateSendC1) : "❌"}
                    autoComplete="off"
                    disabled={true}
                />
            </div>

            <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlInput1">Підрозділ</CFormLabel>
                <AsyncSelect
                    cacheOptions
                    loadOptions={props.loadOptionsFactories}
                    defaultOptions
                    value={props.selectedOptionFactories}
                    onChange={props.handleChangeFactories}
                    isDisabled={props.isDisabledAll || props.isDisabledChange}
                />
            </div>

            <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlInput1">Відповідальний інженер</CFormLabel>
                <Select
                    cacheOptions
                    options={props.selectedOptionEngineers}
                    defaultOptions
                    value={props.selectedEngineer}
                    onChange={props.handleChangeEngineers}
                    className="flex-grow-1"
                    isDisabled={props.isDisabledAll || props.isDisabledChange}
                />
            </div>

            {/* props.isDisabledAll={true} - Заблокувати можливу зміну, оскільки дані вже вневені в 1С */}
            {
                !props.isDisabledAll &&
                <>
                    <CButton color="success" className="me-3" onClick={(event) => props.handleSubmit(event)} disabled={isDisabledPutBtn}>
                        Зберегти
                    </CButton>
                    <CButton color="secondary" onClick={() => props.cancelChange()} disabled={isDisabledCancelBtn}>
                        Відмінити зміни
                    </CButton>
                </>
            }
        </>
    );
};

export default JobData;